.add-event-btn-bottom {
    display: block;
    margin: 0 auto;
    /* -webkit-border-radius: 10px 0 10px 0; */
    /* border-radius: 10px 0 10px 0; */
    font-weight: 700;
    font-size: 14px;
    padding: 15px 40px;
    background-color: #3852b2;
    color: #ffffff;
    
   
}
.new-row {
    width: 100%;
    margin-top: 35px;
    margin-right: 0!important;
    margin-left: 0!important;
 }

 
.footer {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    margin-top:25px;
    width: 100%;
    padding: 10px;
    background-color: #D9D9D9;
    text-align: center;
    color: #202020;
    display: inline-flex;
    justify-content: center;
  
}
.footer a {
padding: 0 0 0 17px;
color:#363736;
}
@media screen and (max-width: 768px) {
    .footer{
        flex-direction: column;
        align-items: center;
    }
    .footer-link{
        order: 1;
        margin-bottom: 8px;
    }
    .celon-right{
        order: 2;
    }
}
@media (min-width: 320px) and (max-width: 540px) {
   .footer {
    font-size:13px;
   }
    .footer a {
        font-size:13px;
        }
}

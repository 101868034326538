


/* content section starts here */
.content-section {
    /* height: 300px; */
    padding: 0 50px;
    margin-top: 15px;
}

.banner__section {
    /* height: 400px; */
    margin-bottom: 85px;
}
.heading-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 0 20px;
}
.see-more-arrow img {
    width: 15px;
}
.heading-wrap h4 {
    font-size: 22px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
.heading-wrap p {
    font-size: 12px;
    font-weight: 800;    
    font-family: 'Poppins', sans-serif;
}
.position_relative {
    position:relative;
    }

    .submit_salon_link {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        text-align: center;
        display: block;
        margin: 0 auto;
        text-decoration: underline solid rgba(171, 214, 220, 0.77) 5px;
    }
    .submit_salon_link:hover {
        text-decoration: underline solid rgba(171, 214, 220, 0.77) 5px;
        color: #000000;
    }   
 
@media screen and (min-width:320px) and (max-width:400px) {
.content-section {
    padding: 0 10px;
}
}

        

@media screen and (min-width:400px) and (max-width:480px) {
.content-section {
    padding: 0 10px;
}
}
            
@media screen and (max-width: 390px) and (min-width: 360px) { 
.pr-0sc {
    padding-right: 0 !important;
}

}

      
.msg{
    width:100%;
    padding:15px;
    text-align:center;
    color: rgba(0, 0, 0, 0.5) !important;
}        

/* .owl-nav {
    position: absolute;
    top: 23px;
} */

.owl-next {
    position: absolute;
    right: 0;
    top:-16px
   
}
.owl-next span {
    color: #ffffff;
}
.owl-prev {
    position: absolute;
    left: 0;
    top:-16px
}
.owl-prev span {
    color:  #ffffff;
}
.text_section {
    padding: 100px 18px;
}
.main-heading div {
    text-align: left;
    line-height: 63px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    color: #000000;
    opacity: 1;
    font-size: 42px;
    position: relative;
    font-weight: bold;
}

.sub-heading {
    font-size: 20px;
    left: 0px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 400;
}
.placeholder-image {
    width: 91%;
    margin-top: 38px;
}
@media screen and (max-width:400px) { 
    .main-heading div {
        font-size: 35px;
        line-height: 50px;
    }
}

@media only screen and (max-width:767px) {
    .date_wrapper_text h2 {
        font-size:19px        
    }
}



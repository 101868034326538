.contact-wrap {
    padding: 44px;
    background-color: #ffffff;
    -webkit-border-radius: 15px 15px 15px 15px;
    border-radius: 15px 15px 15px 15px;
    -webkit-box-shadow: 0 0 20px 2px #E0E0E0;
    box-shadow: 0 0 20px 2px #E0E0E0;
    margin-top: 45px;
    margin-bottom: 45px;
}

.contact-text h4{
    font-family: 'Poppins', sans-serif;
}

.body-color p{
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
    text-align: left !important;
}

.body-color h4{
    color: #212529 !important;
}

.contact-text p {
    font-family: 'Poppins', sans-serif;
}

.custom-input {
    border-radius: 5px;
}

.active-class {
    /* border: 2px solid #abf6db; */
}

.custom-btn {
    border-radius: 50px;
    margin: 0 auto;
    display: block;
    background-color: #abf6db;
    border: 1px solid #abf6db;
    color: #737373;
    font-weight: 700;
}
.event-loader{
    top:30px;
}
.contct-wrapper h2 {
    padding-top: 0;
}
@media screen and (min-width:320px) and (max-width:370px) { 
    .contct-wrapper h2 {
        font-size: 25px;
        padding-top:19px;
    }
    .bg-wrap {
       
        background-image: url("../../images/bg-banner-small.png");
    }
    .contact-wrap {
        margin-top:158px;
    }
}
@media screen and (min-width:371px) and (max-width:400px) { 
    .contct-wrapper h2 {
    font-size: 25px;
    padding-top: 0;
}
.bg-wrap {
    
    background-image: url("../../images/bg-banner-small.png");
}
.contact-wrap {
    margin-top: 211px;
}
}
@media screen and (min-width:401px) and (max-width:479px) { 
    .contct-wrapper h2 {
        padding:8px 101px;
        font-size: 26px;
}
.contact-wrap {
    margin-top: 169px;
}

.contact-wrap p {
    margin-top: 120px;
}

}
@media screen and (min-width:480px) and (max-width:500px) { 
    .bg-wrap {
      
        background-image: url("../../images/bg-banner-small.png");
    }
    .contct-wrapper h2 {
        padding:15px 115px;
        font-size: 26px;
    }
    .contct-wrapper p  {
        margin-top: 96px;
    }
    .contact-wrap {
        margin-top: 152px;
    }
}
@media screen and (min-width:501px) and (max-width:640px) { 
    .contct-wrapper h2 {
        font-size:19px;
        padding-top: 0;
}
}
@media screen and (min-width:640px) and (max-width:768px) { 
    .contct-wrapper h2 {
        font-size: 25px;
        padding-top: 2px!important;
}

}
@media screen and (min-width:768px) and (max-width:787px) { 
    .contct-wrapper h2 {
        padding-top: 32px;
    }
}
@media screen and (min-width:769px) and (max-width:950px) { 

    .contct-wrapper h2 {
        font-size: 25px;
        padding-top: 6px;
    }
}
@media screen and (min-width:767px) and (max-width:768px) { 
    .contct-wrapper h2 {
        padding-top: 32px;
    }
}
@media screen and (min-width:641px) and (max-width:767px) { 
    .contct-wrapper h2 {
        padding-top:42px;
   }
}
.col-centered {
    margin: 0 auto;
}
.form-check-label {
    font-family: 'Poppins', sans-serif;
}
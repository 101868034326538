



/* add event detaile page */
.event-detailes {
    width: 100%;
    /* display: flex; */
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    padding-bottom: 25px;
   
}
.mb-5 {
    margin-bottom: 0.5rem!important;
}
.top-heading {
    position: absolute;
    top: -12px;
    left: 8px;
    font-size: 18px;
    background-color: #ffffff;
    padding: 0 7px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #868686;
}
.upload-wrapper {
    padding:27px 13px;
    -webkit-box-shadow: 0 5px 25px 5px #EBEBEB;
    box-shadow: 0 5px 25px 5px #EBEBEB;
    width: 153px;
    height: 153px;
    margin-top:33px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    position: relative;
    cursor: pointer;
}
.upload-wrapper-event {
    width:210px!important;
    min-width:160px!important;
}
.upload-wrapper {
    text-align: center;
}
.upload-wrapper img {
    width: 25px;
}
.event-name-link {
    padding-top: 20px;
}
.upload-wrapper p {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 13px;

}
.lbl {
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    margin-bottom: 0!important;
}
.evnt-ip {
    border-radius: 0!important;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding-left: 0;
}
.evnt-ip::placeholder {
    font-size: 10px;
}
.evnt-i:focus {
    outline: none;
    box-shadow: none;
}
.virtual-evnt-btn {
    -webkit-border-radius: 0 10px 0 10px;
    border-radius: 0 10px 0 10px;
    border: 1px solid #b5b5b5;
    font-weight: bold;
    font-size: 12px;
    padding: 11px 20px;
    margin-top: 5px;
}
.live-evnt-btn {
    -webkit-border-radius: 10px 0 10px 0;
    border-radius: 10px 0 10px 0;
    border: 1px solid #b5b5b5;
    font-weight: bold;
    font-size: 12px;
    padding:11px 20px;
    margin-left: 10px;
    margin-top: 5px;
}

.submit-detailes {
    padding: 38px 0 0;
}
.submit-event-btn {
    background-color:rgba(171, 214, 220, 0.49);
    border: 1px solid #000000;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px;
    font-weight: bold;
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    width: 192px;
    height: 48px;
    margin-top: 12px;
    cursor: pointer;
    text-align: center;
}
.submit-event-btn:hover {
    color: #ffffff;
}
.btn-image-change {
    background-color: #808080;
    border-radius: 10px 0 10px 0;
    font-weight: bold;
    font-size: 11px;
    padding: 7px 13px;
    margin-left: 10px;
    position: absolute;
    right: 0;
    border: 0;
    color: #ffff;
    bottom: 0;
}
.modal-full-screen {
    width: 96%;
}

/* popup styles */

@media (min-width: 1800px) {
    .modal-full-screen  {
    max-width: 75%!important;
    margin: 0 auto;
}
}

@media (min-width: 992px) and (max-width: 1800px)  {
    .modal-full-screen  {
    max-width: 90%!important;
    margin: 0 auto;
}
}
@media (min-width: 768px) and (max-width: 992px)  {
    .modal-full-screen  {
    max-width: 90%!important;
    margin: 0 auto;
}
.pr-img-wrp {
    width: 100%;
}
}
@media (min-width: 480px) and (max-width: 768px)  {
    .modal-full-screen  {
    max-width: 90%!important;
    margin: 0 auto;
}
.pr-img-wrp {
    width: 100%;
}
}
@media (min-width: 320px) and (max-width: 480px)  {

.pr-img-wrp {
    width: 100%;
}
}
@media (min-width: 404px) and (max-width: 540px)  {

    .date-picker-right .react-datepicker-popper {
        left: -112px!important;
    }
    }
@media (min-width: 320px) and (max-width: 404px)  {

    .date-picker-right .react-datepicker-popper {
        left: -178px!important;
    }
    .date-picker-left .react-datepicker-popper {
        left: -46px!important;
    }
    }   
@media (min-width: 320px) and (max-width: 362px)  { 
    .popup-banner {
    padding: 14px!important
    }
    .upload-wrapper {
        margin-left: 15px;
    }
    .popup-banner h2 {
        font-size: 30px!important;
        margin-bottom: 19px!important;
    }
}    
.popup-banner {
    width: 100%;
    padding: 40px;
}
.popup-banner h2 {
    color: #000000;
    font-weight: 900;
    font-family: 'Poppins', sans-serif;
    font-size: 45px;
}
.popup-banner p {
    margin-top: 6px;
    font-family: 'Poppins', sans-serif;
    margin-bottom:25px;
}
.btn-close-popup {
    position: absolute;
    right: 15px;
    top: 9px;
    /* background-color: #f1f1f1!important;
    padding: 10px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    line-height: 36px; */
}
.btn-close-popup img {
    width: 38px;

}
.calender-ic {
    position: absolute;
    right: 8px;
    top: 7px;
    opacity: 0.5;
    z-index: 1;
    width: 17px;
}

.modal-bg {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;

  
}

.modal-bg .modal-dialog {
    margin-top: 40px;
}


/* Upload image styles  */
.uploaded_image {
    width: 100%!important;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    object-fit: cover!important;
    object-position: center!important;
    border-radius: 10px 10px 10px 10px!important;
    overflow: hidden;
    height: 153px;
  }

  .custom-file-input-ty {
    left:0!important;
    right:0!important;
    bottom: 0!important;
    top: 0!important;
    position: absolute!important;
    height: 153px!important;
   opacity: 0;
   cursor: pointer;
   z-index: 100;
}
.custom-file-input-ty::-webkit-file-upload-button {
    visibility: hidden;
  
  }
  .react-datepicker-popper {
    transform: translate3d(0px,0, 0px)!important;
    width: 333px!important;
  }
  .react-datepicker__input-container input {
      width: 100%!important;
  }
  .react-datepicker-wrapper {
      width: 100%!important;
  }
  .mb-0 {
      margin-bottom: 0!important;
  }
  .po-rel {
      position: relative!important;
  }
  /* Upload image styles  */


  .upload-img-icon {
    width: 25px;
    width: 25px!important;
    height: 28px!important;
    margin: 0px auto;
    display: block;
}

.btn-active {
   background-color:#f6dbab;
}

.error-msg {
    margin-left: 30px;
    color: red;
    font-size: 11px;
    font-family: 'Poppins', sans-serif;
}

.event-date-picker
	{
   display: flex;
   flex-wrap: nowrap;
   margin-top: 18px;
}
.upload-img-icon {
    width: 25px;
    width: 25px!important;
    height: 28px!important;
    margin: 0 auto;
    display: block;
    position: relative!important;
    border-radius: 0!important;
}
.popup-banner p {
    /* margin-top: 6px; */
    font-family: 'Poppins', sans-serif;
    margin-bottom: 25px;
}
.ant-picker { 
    
 border-top:0!important;
border-right:0!important;
border-left:0!important;
border-bottom: 1px solid #D9D9D9!important; }


.ant-picker-input input::-webkit-input-placeholder { /* Edge */
    font-size:10px;
  }
  .ant-picker-input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
      font-size:10px;
  }
  .ant-picker-input input::placeholder {
      font-size:10px;
  }

  .virtual-even-focus {
    background-color: #14b59d;
    color:#ffffff;
 }
 .virtual-even-focus:hover {
    color:#ffffff;
 }
 .live-even-focus {
    background-color: #14b59d;
    color:#ffffff;
 }
 .live-even-focus:hover {
    color:#ffffff;
 }
 .event-loader{
     top:30px;
 }
 .pr-wrap {
     padding-left: 20px;
 }

 .date-picker-wrapper {
  display: flex;  
 }
 .react-datepicker-wrapper input {
     border-top: 0!important;
     border-right: 0!important;
     border-left: 0!important;
     font-size:11px;
     height: 40px!important;
     width:150px!important;
 }

 .react-datepicker-wrapper input::-webkit-input-placeholder { /* Edge */
    font-size:10px;
  }
  .react-datepicker-wrapper input:-ms-input-placeholder { /* Internet Explorer 10-11 */
      font-size:10px;
  }
  .react-datepicker-wrapper input::placeholder {
      font-size:10px;
  }

.start-date{
    margin-right: 5px;
}

.react-datepicker-popper {
    z-index:1000!important;
}
.close-img {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ffffff;
    z-index: 100;
}
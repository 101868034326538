.event-detail-wrapper{
    display: flex;
    flex-direction: column;
}
.max-50 {
    max-width: 39%;
}
.tophead-event-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 29px;
    color: #000000;
    margin-bottom: 0;
    line-height: 38px;
    font-weight: 500;
}
.event-status p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    margin-bottom: 0;
}
.button-green{
    background-color: rgba(171, 214, 220, 0.49);
    border: 1px solid #000000;
    color: #000000;
    font-size: 16px;
    padding: 5px 10px;
    font-weight: 500;
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    width: 192px;
    height: 48px;
    margin-top: 12px;
    cursor: pointer;
    text-align: center;

}
.button-green:focus {outline:0;}
.share-icon{
    width: 50px;
    height:50px;
}
.share-icon img{
    width: 100%;
    height:100%;
  }
  .eventdetails-banner{
    height: 600px;
}
  .eventdetails-banner img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .event-content-text h6{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    line-height: 27px;
  }
  .event-content-text p{
    font-style: normal;
font-weight: normal;
font-size: 14px;
font-family: 'Poppins', sans-serif;
line-height: 25px;
}
.event-content-text ul{
    list-style: none;
}
/* content section starts here */
.content-section {
    /* height: 300px; */
    padding: 0 50px;
    margin-top: 15px;
}

.banner__section {
    /* height: 400px; */
    margin-bottom: 85px;
}
.heading-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 0 20px;
}
.see-more-arrow svg {
    width: 15px;
}
.see-more {
    cursor: pointer; 
}
.heading-wrap h4 {
    font-size: 22px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
.heading-wrap p {
    font-size: 12px;
    font-weight: 800;    
    font-family: 'Poppins', sans-serif;
}
.position_relative {
    position:relative;
}

.font-weight-500 {
    font-weight: 500;
}

    @media screen and (min-width:992px) and (max-width:1024px) {
        .tophead-event-title {
        font-size:44px;
        }
    }
    @media screen and (max-width:992px){
        .max-50 {
            max-width: 100%;
        }
        .eventdetails-banner{
            height: 300px;
        }
        .event-top-section{
            max-width: 720px;
            margin: auto;
        }
    .event-status-wrapper{
            margin-top: 20px;
            margin-bottom: 20px;
            justify-content: space-between;
    }
    .event-top-section{
        max-width: auto;
    }
    .event-status p{
        text-align: left;
    }
    .event-detail-wrapper{
        flex-direction: column-reverse;
    }
    .eventdetails-banner.mt-5{
        margin-top: 0 !important;
    }
    .share-icon{
        font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    }
    .event-lider-wrapper{
        margin-top: 20px !important;
    }
    .event-detail-wrapper{
        border-bottom: 1px solid #F5F5F5;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }
    .tophead-event-title {
        font-size: 30px;
        }
    .event-top-section.mt-5{
        margin-top: 2rem !important;
        padding-bottom: 1rem;
               }
               .share-icon {
                width: 35px;
                height: 35px;
            }
   }  
   @media screen and (max-width:768px){
    .event-top-section{
        max-width: 540px;
        margin: auto;
    }
    .tophead-event-title {
        font-size: 25px;
        }
    .video-wrapper iframe{
        width: 100%;
    }
   
    .share-icon .pl-3{
        padding-left: 5px !important;
    }
    .event-status-wrapper{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .event-content-text{
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important;
    }
    .event-lider-wrapper{
        padding: 0 40px;
    }
   }  
   @media screen and (max-width:575px){
    .event-top-section{
        max-width:100%;
    } 
    .share-icon{
        height: 30px;
        width: 30px;
            }
            .tophead-event-title  {
                font-size: 22px;
            }
            .event-status p {
                font-size: 12px;
                line-height: 19px;
            }
            .button-green {
                font-size: 13px;
            }
            .event-status,
            .tophead-event-title{
                padding-left: 0 !important;
                padding-right:0 !important;
            }
            .event-lider-wrapper{
                padding: 0 15px;
            }
}
@media screen and (min-width:320px) and (max-width:400px) {
.content-section {
    padding: 0 15px;
}
.trending-events-wrapper{
    margin: 0 0;
}
.heading-wrap h4 {
    font-size:18px;
}
.footer a {
    padding: 0 0 0 8px;
}
.item {
    width: 290px!important;
}
}

        

@media screen and (min-width:400px) and (max-width:480px) {

}
            
@media screen and (max-width: 390px) and (min-width: 360px) { 
.pr-0sc {
    padding-right: 0 !important;
}

}

      
.msg{
    width:100%;
    padding:15px;
    text-align:center;
    color: rgba(0, 0, 0, 0.5) !important;
}        

/* .owl-nav {
    position: absolute;
    top: 23px;
} */

.owl-next {
    position: absolute;
    right: 0;
    top:-16px
   
}
.owl-next span {
    color: #ffffff;
}
.owl-prev {
    position: absolute;
    left: 0;
    top:-16px
}
.owl-prev span {
    color:  #ffffff;
}
.text_section {
    padding: 100px 18px;
}


.sub-heading {
    font-size: 20px;
    left: 0px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 400;
}
.placeholder-image {
    width: 91%;
    margin-top: 38px;
}
.date_wrapper_text {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding: 21px 0;
}
.date_wrapper_text h2 {
    font-weight: 600;
}
.sub_text_one {
 
    text-align: center;
}
.sub_text_italics {
    font-style: italic;
    font-weight: 500;
    text-align: center;
    font-weight: 600;
}




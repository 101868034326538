
input:focus, textarea:focus, select:focus{
    outline: none!important;
    box-shadow: 0 0 0 0.2rem rgba(0,0,0,0)!important;
}
.padding-zero {
    padding: 0!important;
}
.yellow-bg {
    background-color: #f6dbab!important;
}
.light-green {
    background-color: #abf6db;
}
.grey-bg {
    background-color: #808080;
}
.navbar-logo img {
    width: 92px;
}
.navbar-top {
    height: 75px;
    background-color: #FAF8F7;
    border-bottom: 1px solid #E6E6E6;
}
.navbar-logo {
    color: #000000;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    cursor: pointer;
}
.navbar-wrap{
    justify-content: flex-end;
}
.navbar-list {
    color: black;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    height: 75px;
    line-height: 55px;
    font-size: 14px;
    background-color: #FAF8F7;
    
}
.navbar-collapse-menu {
    z-index: 100!important;
}
.hamberger-menu {
   width: 18px;
}
.navbar-list li {
    /* padding: 0 25px; */
    background-color: #FAF8F7;
    display: flex;
    align-items: center;
}
.navbar-list li a {
    color: black;
    font-size: 14px;
    cursor: pointer;
}
.active-class {
    /* border: 2px solid #abf6db; */
    border-top: none;
}


/* content section starts here */

.banner {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 123px;
        left: 0;
        right: 0;
   
  }
.bg-image {
    width: 750px;
    position: absolute;
    left: 0;
    right: 0;
  
    margin: 0 auto;
    text-align: center;
}
// .banner h2 {
//     color: #000000;
//     font-weight: 900;
//     font-family: 'Raleway', sans-serif;
//     font-size: 45px;
// }
// .banner p {
//     margin-top: 6px;
//     font-family: 'Lato', sans-serif;
// }
.heading-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
.see-more-arrow img {
    width: 15px;
}
.heading-wrap h4 {
    font-size: 32px;
    font-weight: 700;
    font-family: 'Luna';
}
.heading-wrap p {
    font-size: 12px;
    font-weight: 800;    
    font-family: 'Poppins', sans-serif;
}


.overlay-wrap {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius:10px 10px 10px 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.nav-link { 
    cursor: pointer; 
    line-height: 23px;
}
.become_member {
    border: 1px solid #000000;
    padding: 2px 16px;
    height: 48px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(171, 214, 220, 0.49);
}
.talk_to_us {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}


@media screen and (max-width:992px) {
    .navbar-list {
        height: 52px;
        line-height: 33px;
    }
    .navbar-list li {
        padding: 0 25px;
        height: 71px;
        background-color: #ffffff;
    }
}
    
@media screen and (min-width:768px) and (max-width:980px) {
    .banner {
        padding: 50px;
        top:75px;
    }
    .banner h2 {
        font-size: 37px;
    }
}
@media screen and (min-width:640px) and (max-width:768px) { 
    .banner h2 {
        font-size: 37px;
    }
}


@media screen and (min-width:480px) and (max-width:640px) { 
    .banner h2 {
        font-size: 25px;
    }
    .navbar-list li {
        text-align: center;
    }
}
@media screen and (min-width:320px) and (max-width:480px) { 
    .banner h2 {
        font-size: 18px;
    }
    .banner {
        top: 82px;
    }
    .navbar-list li {
        text-align: center;
    }
}
@media screen and (min-width:641px) and (max-width:991px) {
    .nav-link {
     text-align: center;
 }
 }

.active-class {
    /* border: 2px solid #abf6db; */
}


.navbar-toggler:focus {
    border: none!important;
    outline: none!important;
    box-shadow: none!important;
}

.iframe-data {
    height: calc(100vh - 146px);
    iframe {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        border: 0;
    }
}


.event_wrapper {
    /* background-image: url("../../../assets/images/combined@2x.png")!important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; */
    max-width: 1000px;
    height: 525px;
    margin-top: 45px;
    position: relative;
  
}
.detail_d {
    padding: 24px;
    color: #ffffff;
    position: relative;
    height: 520px;
    max-width: 984px;
    margin: 0 auto;
}
.detail_d img {
    width: 100%;
    height: 470px;
    object-fit: cover;
}
.text_ta_wrapper {
    width:300px;
    height: 45px;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0;
    top: 0;
    height: 90.3%;
    margin-top: 24px;
  
}
.text_ta_wrapper h2 {
    color: #C39B4C;
    padding: 87px 45px 15px 45px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin-bottom: 0;
}
.large_text {
    line-height: 28px;
    font-size: 23px;
}
.small_text {
    line-height: 41px;
    font-size: 31px;
}
.text_ta_wrapper p {
    color:#ffffff;
    padding: 0 45px;
    font-family: 'Pridi', serif;
    font-weight:400;
    margin-bottom: 0;
    font-size: 19px;
 
}
.featured_btn {
    color: #000000;
    background-color: #C39B4C;
    border-radius: 0 0 5px 5px;
    border:none;
    width: 111px;
    height: 39px;
    position: absolute;
    top: 0;
    left: 19px;
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    font-weight: 900;
}
.learn__more {
    color: #AAF0D1 !important;
    float: right;
    padding-top: 20px;
    padding-right: 84px;
    font-size: 13px;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    font-weight: 600;
}
.learn__more:hover {
    color: #AAF0D1;
    text-decoration: none;
}
.learn__more  img {
    width: 14px!important;
    height: 10px!important;
}
.presenter_wrapp {
    padding: 15px;
    position: absolute;
    bottom: 0;
}
.presenter_wrapp p {
    color:#ffffff;
    padding: 0!important;
    font-family: 'Pridi', serif;
    font-weight:400;
    margin-bottom: 0;
    font-size: 16px;

   
}
.presenter_wrapp p:nth-child(1) {
    opacity: 0.8;
}
.featured-image {
    width: 68%;
}
.joint_event {
    background-color: #AAF0D1;
    color: #000000;
    border:none;
    width: 111px;
    height: 39px;
    position: absolute;
    bottom: 48px;
    right: 48px;
    font-family: "Nunito", serif;
    font-size: 13px;
    font-weight: 900;
}
.joint_event:focus {
    outline: none;
}


  .yel_one {
    position: absolute;
    width: 90%;
    height: 250px;
    top: -2px;
    left: 0;
    border: 5px solid #6B5B77;
  }
  .green_one {
    position: absolute;
     width: 90%;
    height:250px;
    bottom: 3px;
    right: 0;
    border: 5px solid rgba(242, 206, 189, 0.49);

  
  }

  @media only screen and (min-width: 320px) and  (max-width:480px) {
    .event_wrapper {
       height: auto;
    }
    .detail_d {
        height: auto;
    }
    .detail_d img {
        height: auto;
    }
    .green_one {
        height: auto;
    }
    .yel_one {
        height: 110px;
    }
    .green_one {
        height: 110px;
    }
    .text_section {
        padding: 50px 18px!important;
    }
  }
  @media only screen and (min-width: 480px) and  (max-width:640px) { 
    .event_wrapper {
        height: auto;
     }
     .detail_d {
         height: auto;
     }
     .detail_d img {
         height: auto;
     }
     .green_one {
         height: auto;
     }
     .yel_one {
         height: 110px;
     }
     .green_one {
         height: 110px;
     }
  }
.contact-text h4{
    font-family: 'Raleway', sans-serif;
}

.contact-text p {
    font-family: 'Lato', sans-serif;
}

.body-color p{
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
    text-align: left !important;
}

.body-color h4{
    color: #212529 !important;
}

#snackbar ,#errorSnack{
  visibility: hidden;
  min-width: 195px;
  max-width: 300px;
  margin-left: -125px;
  font-size: large;
  background-color: #00c689;
  color: #fff;
  text-align: center;
  /* border-radius: 2px; */
  padding: 23px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 75px;
  min-height: 63px;
  max-height: 150px;
  z-index: 1900;
  
}


/* visibility: hidden;
min-width: 195px;
width: 324px;
margin-left: -125px;
background-color: #00c689;
color: #fff;
text-align: center;
padding: 18px;
position: fixed;
z-index: 1;
left: 18%;
top: 75px;
margin: 0 auto;
z-index: 1900; */

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show ,#errorSnack.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
    However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.add-event-btn-bottom {
    background-color:rgba(171, 214, 220, 0.49);
    border: 1px solid #000000;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px;
    font-weight: bold;
    line-height: 36px;
    font-family: "Nunito", serif;
    width: 192px;
    height: 48px;
    margin-top: 12px;
    cursor: pointer;
    text-align: center;
}
.btn:hover {
    color: #000000;
    text-decoration: none;
}
.btn:focus {
    box-shadow: none;
}
.grid {
    max-width:320px;
    margin: 0 auto;
    margin-bottom: 15px;
 }
 .grid-box-top {
     height: 189px;
     -webkit-border-radius: 10px 10px 10px 10px;
     border-radius: 10px 10px 10px 10px;
     position: relative;
     /* -webkit-box-shadow: 0 0 20px 2px #E0E0E0;
     box-shadow: 0 0 20px 2px #E0E0E0; */
     -webkit-box-shadow: 0 15px 25px 5px #EBEBEB;
     box-shadow: 0 15px 25px 5px #EBEBEB;
     }
 .overlay-wrap {
     /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
 background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
 background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
 background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
 
     -webkit-border-radius: 10px 10px 10px 10px;
     border-radius:10px 10px 10px 10px;
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     cursor: pointer;
 }
 .grid-box-top img {
     object-fit: cover;
     object-position: center;
     height: 100%;
     -webkit-border-radius: 10px 10px 10px 10px;
     border-radius: 10px 10px 10px 10px;
 }
 .grid-box-bottom {
     height: 52px;
     margin-top: 15px;
     display: flex;
     justify-content: center;
 }
 .presenter-wrap {
     display: flex;
 }
 .virual-wrap {
    position: absolute;
    top: 0;
    right: 15px;
    height: 49px;
    text-align: center;
    width: 74px;
    background-color: #000000;
    color: #ffffff;
    padding: 7px;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
    font-weight: 700;
    line-height: 52px;
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
}
.joint-event {
       
    background-color:rgba(171, 214, 220, 0.49);
    border: 1px solid #000000;
    color: #000000;
    font-size: 16px;
    padding: 5px 10px;
    font-weight: 700;
    line-height: 36px;
    font-family: 'Open Sans', sans-serif;
    width: 192px;
    height: 48px;
    margin-top: 12px;
    cursor: pointer;
    text-align: center;
    }
         
 .bottom-text-wrapper {
     position: absolute;
     bottom: 0;
     padding-right: 10px;
     padding-left: 10px;
     overflow: hidden;
     cursor: pointer;
     
 }
 
 .bottom-text-wrapper .top-text {
     font-size: 16px;
     font-weight: 800;
     color: #abf6db;
     margin-bottom: 0;
 }
 .bottom-text-wrapper h3 {
    font-family: 'Luna';
    line-height: 22px;
    font-weight: 600;
    color: #fff;
    font-size: 24px;
    display: block;
    display: -webkit-box;
    max-width: 87%;
     
 }
 
 .bottom-text-wrapper .btm-text {
     font-size: 16px;
     font-weight: 700;
     color: #cccccc;
     font-size: 12px;
     font-family: 'Open Sans', sans-serif;
     margin-bottom: 9px;    
     white-space: nowrap;
     text-overflow: ellipsis;
     overflow: hidden;
     width: 300px;
 }
 .presenter-pro-pic {
     width: 50px;
     height: 50px;
     -webkit-border-radius: 10px 10px 10px 10px;
     border-radius:10px 10px 10px 10px;
     cursor: pointer;
 }
 .presenter-pro-pic img {
    width: 100%;
    object-fit: cover;
    height: 50px;
     -webkit-border-radius: 10px 10px 10px 10px;
     border-radius:10px 10px 10px 10px;
 }


 .presenter-list{
     padding-left: 10px;
     margin-bottom: 0;
     margin-top: 10px;
     line-height: 15px;
     cursor: pointer;
 }
 .presenter-list li {
     list-style: none;
     
 }
 .presenter-list li:nth-child(1) {
     font-size: 12px;
     font-family: 'Lato', sans-serif;    
 }
 .presenter-list li:nth-child(2) {
     font-weight: bold;
     font-family: 'Raleway', sans-serif;
     white-space: nowrap;
     text-overflow: ellipsis;
     overflow: hidden;
     width: 169px;
     height: 20px;
 }
 /* .joint-event {
    background-color: #3852b2;
    color:#ffffff;
     -webkit-border-radius: 10px 0 10px 0;
     border-radius:10px 0 10px 0;
     font-size: 11px;
     padding: 5px 10px;
     font-weight: bold;
     line-height: 26px;
     font-family: 'Lato', sans-serif;
     height: 36px;
     margin-top: 12px;
     cursor: pointer;
     }
      */

     
     
        @media screen and (max-width: 480px) and (min-width: 320px) {
            .presenter-list li:nth-child(2) {
              width:104px !important;
             
            }
        }
            
        
        @media screen and (min-width: 600px) and (max-width:2600px) {
                        .owl-item {
                width:320px!important;
                }
                .owl-dots{
                    display: none;
                }
            
        }
        
        @media screen and (max-width: 390px) and (min-width: 360px) { 
        
        }

        .owl-item {
            width: 320px!important;
       margin-right: 28px;
        }
        .owl-item .active {
            width: 320px!important;
        }
        .item {
            width: 320px!important;
        }
    .owl-stage {
        display: flex;
    }
.owl-dots {
    height: 15px;
    overflow: hidden;
}
     
            
            
            
            
            

.bg-wrap {
   
    background-image: url("../../images/bg-banner.png")!important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 290px;
    padding: 68px;
    width: 100%;
    position: relative;
    margin-right: 0!important;
    margin-left: 0!important;
   
}
.banner {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 114px;
        left: 0;
        right: 0;
   
  }
.bg-image {
    width: 750px;
    position: absolute;
    left: 0;
    right: 0;
  
    margin: 0 auto;
    text-align: center;
}
.banner h2 {
    color: #000000;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    font-size: 45px;
    padding: 0 94px;
}
.banner p {
    margin-top: 116px;
    font-family: 'Poppins', sans-serif;
}
@media screen and (min-width:1000px) and (max-width:1250px) {
    .banner {
        padding: 50px;
        top:75px;
    }
    .banner h2 {
        font-size:33px;
        padding: 0 55px;
    }
}
@media screen and (min-width:930px) and (max-width:980px) {
    .banner {
        padding: 50px;
        top:75px;
    }
    .banner h2 {
        font-size: 33px;
        padding: 0 55px;
    }
}
@media screen and (min-width:980px) and (max-width:1000px) { 
    .banner h2 {
        font-size: 32px;
    }
    .banner {
        top: 122px;
    }
    .banner p {
        margin-top: 90px;
    }
}
@media screen and (min-width:930px) and (max-width:980px) { 
    .banner h2 {
         font-size: 27px;  
    }
    .banner p {
        margin-top: 89px;
    }
}
@media screen and (min-width:800px) and (max-width:930px) { 
    .banner h2 {
        font-size: 27px;  
   }
   .banner p {
    margin-top: 83px;
    
}
   .banner {
       top: 77px;
   }
}
@media screen and (min-width:769px) and (max-width:800px) { 
    .banner h2 {
        font-size: 26px;  
   }
   .banner {
    top: 77px;
}

.banner p {
    margin-top: 85px;
}

}
@media screen and (min-width:640px) and (max-width:768px) { 
    .banner h2 {
        font-size: 21px;
        padding: 0 26px;
    }
    .banner {
        top: 129px;
    }
    .banner p {
        margin-top: 62px;
    }
}
/* @media screen and (min-width:768px) and (max-width:787px) { 
    .banner {
        top: 69px;
    }
} */
@media screen and (min-width:600px) and (max-width:640px) { 
    .banner h2 {
        font-size: 21px;
        padding: 0 38px;
    }
   
    .banner {
        top: 130px;
    }
    .banner p {
        margin-top: 58px;
        padding: 0 49px;
        font-size: 14px;
    }

}
@media screen and (min-width:560px) and (max-width:600px) { 
    .banner h2 {
        font-size: 19px;
    }
    .banner p {
        margin-top: 57px;
        font-size: 13px;
        padding: 0 30px;
    }
    .banner {
        top: 133px;
    }
}
@media screen and (min-width:500px) and (max-width:560px) { 
    .banner {
        top: 132px;
    }

    .banner h2 {
        font-size: 17px;
    }
    .banner p {
        margin-top: 60px;
        font-size: 13px;
    }
}
@media screen and (min-width:400px) and (max-width:500px) { 
    .banner h2 {
        padding: 0 100px;
        font-size: 26px;
        text-align: left;

    }
    .banner {
        top: 87px;
    }
    .banner p {
        margin-top: 100px;
        font-size: 18px;
        padding: 0 32px;
        font-weight: 600;
    }
    .bg-wrap {
        height: 250px;
        background-image: url("../../images/bg-banner-small.png")!important;
    }
    .content-section {
       
        padding: 0 20px;
        margin-top: 113px;
    }
}
@media screen and (min-width:371px) and (max-width:400px) { 
    .banner h2 {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: -3px;
        text-align: left;

     }
    .banner {
        top:54px;
    }
    .banner p {
        padding: 0 21px 21px;
        font-size: 19px;
        font-weight: 600;
        margin-top: 107px;
        
     }
    .bg-wrap {
        padding: 0;
        height:210px;
        background-image: url("../../images/bg-banner-small.png")!important;
    }
    .content-section {
        margin-top:140px;
    }
}
@media screen and (min-width:320px) and (max-width:370px) { 
    .banner h2 {
        font-size: 25px;
        padding: 21px 64px;
        text-align: left;

    }
    .banner p {
        margin-top: 82px;
        padding: 0 17px 17px;
        font-size: 19px;
        font-weight: 600;
    }
    .content-section {
        /* height: 300px; */
        padding: 0 20px;
        margin-top: 133px;
    }
    .banner {
      top:80px;
    }
    .bg-wrap {
        padding: 0;
        background-image: url("../../images/bg-banner-small.png")!important;
    }
}
.terms-wrapper {
    padding-top: 100px;
}
.terms-wrapper  h1 {
    
    color: #000000;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    font-size: 36px;
    padding-bottom: 20px;
}
.terms-wrapper  h2 {
    
    color: #000000;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    
}

.terms-wrapper p {
    font-family: 'Lato', sans-serif;
}
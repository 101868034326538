@font-face {
  font-family: 'Poppins';
  src:  url(./fonts/Poppins-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'Raleway';
  src: url(./fonts/Raleway-Regular.ttf);
}
@font-face {
  font-family: 'Lato';
  src: url(./fonts/Lato-Regular.ttf);
}
@font-face {
  font-family: 'Pridi';
  src: url(./fonts/Pridi-Regular.ttf);
}
@font-face {
  font-family: 'Nunito';
  src: url(./fonts/Nunito-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'Nunito';
  src: url(./fonts/Nunito-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'Nunito';
  src: url(./fonts/Nunito-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto Slab';
  src: url(./fonts/RobotoSlab-Regular.ttf);
}

/* loader */
body {
  background-color:#FAF8F7 !important;
}
.lds-dual-ring {
    display: inline-block;
    width: 70%;
    height: 80px;
    position: absolute;
    right: 0;
    top: 186px;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
  }
  .lds-dual-ring::before {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #ABF6DB;
    border-color: #ABF6DB transparent #ABF6DB transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    background: #dcdcdc transparent ;
    margin: auto;
  }
  .progressing-text{
    text-shadow: 0px 0px 30px rgb(0, 0, 0);
    color: #fff;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .owl-prev span {
    font-size: 119px!important;
  }
  
  .owl-prev:hover {
    background-color: transparent!important;
    color: #212529!important;
  }
  .owl-next span {
    font-size: 119px!important;
  }
  .owl-next:hover {
    background-color: transparent!important;
    color: #212529!important;
  }
  .owl-next:focus {
    box-shadow: none!important;
    outline: none!important;
  }
  .owl-prev:focus {
    box-shadow: none!important;
    outline: none!important;
  }
  .owl-next span:focus {
    box-shadow: none!important;
    outline: none!important;
  }
  .owl-prev span:focus {
    box-shadow: none!important;
    outline: none!important;
  }


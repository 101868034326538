@import "pages/BannerComponent";
@import "pages/ContactDetails";
@import "pages/ContactUs";
@import "pages/ErrorSnackbarComponent";
@import "pages/EventComponent";
@import "pages/EventDetails";
@import "pages/EventDetailsWrapper";
@import "pages/EventListing";
@import "pages/EventListWrapper";
@import "pages/EventModal";
@import "pages/FeaturedImageWrapper";
@import "pages/footer";
@import "pages/navbar";
@import "pages/privacyPolicy";
@import "pages/SubmitButtonComponent";
@import "pages/termsOfService";

@font-face {
    font-family: 'Luna';
    src: url('../font/font.woff');
}
